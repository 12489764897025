import React from 'react'
import {
    TextField,
} from '@material-ui/core'

function ViewTextField({
    label,
    value,
}) {
    return (
        <TextField
            variant="outlined"
            fullWidth
            label={label}
            value={value}
            readonly
        />
    )
}

export default ViewTextField