import React from 'react'
import {
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    DialogActions,
} from '@material-ui/core'

function FormDialog({
    title,
    isOpen,
    setClose,
    onSubmit,
    isLoading,
    isUpdate,
    children,
}) {
    return (
        <Dialog
            open={isOpen}
            onClose={setClose}
            scroll="paper"
            fullWidth
            maxWidth="lg"
        >
            <DialogTitle id="dialog-title">{title}</DialogTitle>
            <DialogContent>
                {children}
            </DialogContent>
            <DialogActions>
                <Button variant="contained" color="seconary" onClick={setClose}>
                    Cancel
                </Button>
                <Button variant="contained" color="primary" disabled={isLoading} onClick={onSubmit}>
                    {isUpdate ? 'Update' : 'Create'}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default FormDialog