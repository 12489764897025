import React from 'react'
import {
    makeStyles,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    IconButton,
} from '@material-ui/core'

import VisibilityIcon from '@material-ui/icons/Visibility'
import CreateIcon from '@material-ui/icons/Create'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'

const useStyles = makeStyles({
    table: {
        width: '100%'
    }
})

function BaseTable({
    withIndex,
    headers,
    data,
    getRow,
    hasAction,
    onViewHandler,
    onEditHandler,
    onDeleteHandler,
}) {
    const classes = useStyles()

    return (
        <Paper style={{ maxHeight: 450, overflow: 'auto'}}>
            <TableContainer component={Paper}>
                <Table className={classes.table}>
                    <TableHead>
                        { withIndex && 
                            <TableCell>#</TableCell>
                        }
                        { headers.map((header, index) => (
                                <TableCell align="center" key={index}>{header}</TableCell>
                            ))
                        }
                        { hasAction && 
                            <TableCell>Action</TableCell>
                        }
                    </TableHead>
                    <TableBody>
                        { data.map((row, index) => (
                            <TableRow key={row.id}>
                                { withIndex && 
                                    <TableCell>{index + 1}</TableCell>
                                }
                                { getRow(row) }
                                { hasAction && 
                                    <TableCell>
                                        { onViewHandler && 
                                            <IconButton 
                                                color="primary"
                                                onClick={() => { onViewHandler(row) }}>
                                                <VisibilityIcon />
                                            </IconButton>
                                        }
                                        { onEditHandler && 
                                            <IconButton 
                                                color="primary"
                                                onClick={() => { onEditHandler(row) }}>
                                                <CreateIcon />
                                            </IconButton>
                                        }
                                        { onDeleteHandler && 
                                            <IconButton 
                                                color="secondary"
                                                onClick={() => { onDeleteHandler(row) }}>
                                                <DeleteForeverIcon />
                                            </IconButton>
                                        }
                                    </TableCell>
                                }
                                
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    )
}

export default BaseTable