import React, { useState } from 'react'
import {
    Grid,
    TextField,
} from '@material-ui/core'

import NullableDatePicker from '../field/NullableDatePicker'
import Selectable from '../field/Selectable'
import FormDialog from '../FormDialog'
import useObject from '../../hook/useObject'

import helpers from '../../helper'
import apis from '../../api'

function APIKeyForm({
    title,
    isOpen,
    setClose,
    onCreated,
    emails,
    apikey,
}) {
    const [ keyInfo, setKeyInfo ] = useObject(apikey ? apikey : {
        type: '',
        label: '',
        expiredAt: null,
        emailId: '',
    })

    const [ error, setError ] = useObject({})

    const [ submitting, setSubmitting ] = useState(false)

    const onTypeChangeHandler = (e) => {
        setKeyInfo('type', e.target.value)
        setError('type', '')
    }

    const onLabelChangeHandler = (e) => {
        setKeyInfo('label', e.target.value)
        setError('label', '')
    }

    const onExpiredAtChangeHandler = (date) => {
        setKeyInfo('expiredAt', date)
        setError('expiredAt', '')
    }

    const onEmailIdChangeHandler = (e) => {
        setKeyInfo('emailId', e.target.value)
        setError('emailId', '')
    }

    const onSubmitHandler = async (e) => {
        e.preventDefault()
        setSubmitting(true)

        let hasError = false

        if (keyInfo.type === '') {
            setError('type', 'type cannot be empty')
            hasError = true
        } 
        if (keyInfo.label === '') {
            setError('label', 'label cannot be empty')
            hasError = true 
        }
        if (keyInfo.type === 'sender') {
            if (keyInfo.emailId && keyInfo.emailId === -1) {
                setError('emailId', 'email is required for sender api')
                hasError = true
            }
        }

        if (hasError) {
            setSubmitting(false)
            return
        }

        try {
            let request = null
            if (keyInfo.type === 'sender') {
                request = apis.apikeys.create(
                    keyInfo.type, 
                    keyInfo.label,
                    keyInfo.expiredAt,
                    keyInfo.emailId,
                )
            } else {
                request = apis.apikeys.create(
                    keyInfo.type,
                    keyInfo.label,
                    keyInfo.expiredAt,
                )
            }

            if (request) {
                const { data } = await request

                onCreated && onCreated(data)
            }
        } catch (err) {
            helpers.errors.alertError(err)
        } finally {
            setSubmitting(false)
        }
    }

    return (
        <FormDialog
            title={title}
            isOpen={isOpen}
            setClose={setClose}
            isLoading={submitting}
            isUpdate={!!apikey}
            onSubmit={onSubmitHandler}
        >
            <Grid container spacing={3}>
                <Grid item xs={12} md={6} lg={6}>
                    <TextField
                        variant="outlined"
                        fullWidth
                        label="label"
                        value={keyInfo.label}
                        onChange={onLabelChangeHandler}
                        error={!!error.label}
                        helperText={error.label}
                    />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                    <Selectable
                        label="type"
                        value={keyInfo.type}
                        onValueChange={onTypeChangeHandler}
                        errorMessage={error.type}
                        defaultSelectValue={''}
                        defaultSelectText={''}
                        list={[
                            { value: 'sender', label: 'Sender' },
                            { value: 'reminder', label: 'Reminder' },
                            { value: 'testing', label: 'Testing' },]}
                        valueKey={'value'}
                        textKey={'label'}
                    />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                    <NullableDatePicker
                        label={'expired at'}
                        value={keyInfo.expiredAt}
                        onValueChange={onExpiredAtChangeHandler}
                        errorMessage={error.expiredAt}
                    />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                    <Selectable
                        label="email"
                        value={keyInfo.emailId}
                        onValueChange={onEmailIdChangeHandler}
                        errorMessage={error.emailId}
                        defaultSelectValue={''}
                        defaultSelectText={''}
                        list={emails}
                        valueKey={'id'}
                        textKey={'label'}
                    />
                </Grid>
            </Grid>
        </FormDialog>
    )
}

export default APIKeyForm