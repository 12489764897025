import actions_constant from "../actions/actions_constant";

export default function authReducer(state = {
    id: 0,
    username: '',
    displayname: '',
    roles: '',
}, action) {
    switch (action.type) {
        case actions_constant.LOGIN_INFO:
            return {
                id: action.id,
                username: action.username,
                displayname: action.displayname,
                roles: action.roles,
            }
        case actions_constant.DISPLAY_CHANGE:
            return {
                ...state, 
                displayname: action.displayname,
            }
        case actions_constant.LOGOUT:
            return {
                id: 0,
                username: '',
                displayname: '',
                roles: ''
            }
        default:
            return state
    }
}