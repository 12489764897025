import getAxios from './axios'

const register = (username, password, displayname, role, activated) => getAxios({
    url: '/user/register',
    method: 'POST',
    data: {
        username,
        password,
        displayname,
        role,
        activated,
    },
})

const list = () => getAxios({
    url: '/user',
    method: 'GET',
})

const update = (userId, displayname, role, activated) => getAxios({
    url: `/user/${userId}`,
    method: 'PUT',
    data: {
        displayname,
        role,
        activated,
    }
})

const update_displayname = (display) => getAxios({
    url: '/user/display',
    method: 'PATCH',
    data: {
        display,
    }
})

const change_password = (old, newpass) => getAxios({
    url: '/user/password',
    method: 'PATCH',
    data: {
        old,
        new: newpass
    }
}) 

const remove = (userId) => getAxios({
    url: `/user/${userId}`,
    method: 'DELETE',
})

const users = {
    register,
    list,
    update,
    update_displayname,
    change_password,
    remove,
}

export default users