import getAxios from './axios'
import helpers from '../helper'

const list = () => getAxios({
    url: '/api',
    method: 'GET',
})

const create = (type, label, expiredTime, emailId = '') => {
    let data = {
        label,
    }
    if (expiredTime) {
        data.expiredTime = helpers.time.toRFC3339Date(expiredTime)
    }

    if (type === 'testing') {
        return getAxios({
            url: '/api/testing',
            method: 'POST',
            data,
        })
    }
    if (type === 'sender') {
        data.emailId = emailId
        return getAxios({
            url: '/api/sender',
            method: 'POST',
            data,
        })
    }
    return getAxios({
        url: '/api/reminder',
        method: 'POST',
        data,
    })
}

const remove = (apiId) => getAxios({
    url: `/api/${apiId}`,
    method: 'DELETE',
})

const apikeys = {
    list, 
    create,
    remove,
}

export default apikeys