
import React, { useState, useEffect, useMemo } from 'react'
import {
    Grid,
} from '@material-ui/core'
import Pagination from '@material-ui/lab/Pagination'

import ListCard from '../components/ListCard'
import ReminderForm from '../components/dialog/ReminderForm'
import ReminderView from '../components/dialog/ReminderView'
import ReminderTable from '../components/table/ReminderTable'

import helpers from '../helper'
import apis from '../api'

function Reminder() {
    const [ emails, setEmails ] = useState([])
    const [ reminders, setReminders ] = useState([])
    const [ reminderCount, setCount ] = useState(0)
    const [ pageSize, setPageSize ] = useState(0)
    const [ page, setPage ] = useState(1)
    const [ isCreatingReminder, setCreatingReminder ] = useState(false)
    const [ viewingReminder, setViewingReminder ] = useState(null)
    const [ editingReminder, setEditingReminder ] = useState(null)
    const pageCount = useMemo(() => {
        if (reminderCount === 0 || pageSize === 0) {
            return 0
        }
        return parseInt(reminderCount / pageSize) + 1
    }, [reminderCount, pageSize])

    const onPageChangeHandler = (event, value) => {
        setPage(value)
    }

    const fetchEmails = async () => { 
        try {
            const { data } = await apis.emails.list()
            setEmails(data)
        } catch (e) {
            helpers.errors.alertError(e)
        }
    }

    const fetchReminders = async () => { 
        try {
            const { data } = await apis.reminders.list(page)
            setReminders(data.reminders)
            setCount(data.count)
            setPageSize(data.size)
        } catch (e) {
            helpers.errors.alertError(e)
        }
    }

    const onAddReminderClickHandler = () => {
        setCreatingReminder(true)
    }

    const onReminderViewHandler = (reminder) => {
        setViewingReminder(reminder)
    }

    const onReminderEditHandler = (reminder) => {
        setEditingReminder(reminder)
    }

    const onReminderDeleteHandler = async (reminder) => {
        if (!window.confirm(`are you sure you want to delete ${reminder.label}`)) return
        try {
            await apis.reminders.remove(reminder.id)
        } catch (e) {
            //helpers.errors.alertError(e)
        } finally {
            fetchReminders()
        }
    }

    const onCreatedHandler = () => {
        setCreatingReminder(false)
        fetchReminders()
    }

    const onEditedHandler = () => {
        setEditingReminder(null)
        fetchReminders()
    }

    useEffect(() => {
        fetchReminders()
    }, [page])

    useEffect(() => {
        fetchEmails()
        fetchReminders()
    }, [])

    return (
        <>
            <Grid container>
                <Grid item xs justifyContent="center" className={'flexDisplay'}>
                    <ListCard
                        title={'Reminder'}
                        onAddClickHandler={onAddReminderClickHandler}
                    >
                        <Grid container>
                            <Grid item xs justifyContent="center" className={'flexDisplay'}>
                                <Pagination 
                                    count={pageCount} 
                                    page={page} 
                                    onChange={onPageChangeHandler}
                                    variant="outlined" />
                            </Grid>
                        </Grid>
                        <ReminderTable
                            data={reminders}
                            emails={emails}
                            onViewHandler={onReminderViewHandler}
                            onEditHandler={onReminderEditHandler}
                            onDeleteHandler={onReminderDeleteHandler}
                        />
                    </ListCard>
                </Grid>
            </Grid>
            { isCreatingReminder && 
                <ReminderForm
                    title={'Creating Reminder'}
                    isOpen={isCreatingReminder}
                    setClose={() => { setCreatingReminder(false) }}
                    emails={emails}
                    onCreated={onCreatedHandler}
                /> 
            }
            { editingReminder && 
                <ReminderForm
                    title={'Editing Reminder'}
                    isOpen={!!editingReminder}
                    setClose={() => setEditingReminder(null) }
                    emails={emails}
                    reminder={editingReminder}
                    onEdited={onEditedHandler}
                />
            }
            { viewingReminder && 
                <ReminderView
                    reminder={viewingReminder}
                    emails={emails}
                    isOpen={!!viewingReminder}
                    setClose={() => setViewingReminder(null) }
                />
            }
        </>
    )
}

export default Reminder