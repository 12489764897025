import React, { useState, useEffect } from 'react'
import {
    Grid,
} from '@material-ui/core'

import ListCard from '../components/ListCard'
import EmailForm from '../components/dialog/EmailForm'
import EmailTable from '../components/table/EmailTable'

import helpers from '../helper'
import apis from '../api'

function Email() {
    const [ emails, setEmails ] = useState([])
    const [ isCreatingEmail, setCreatingEmail ] = useState(false)

    const fetchEmails = async () => {
        try {
            const { data } = await apis.emails.list()
            setEmails(data)
        } catch (e) {
            helpers.errors.alertError(e)
        }
    }

    const onAddEmailClickHandler = () => {
        setCreatingEmail(true)
    }

    const onEmailDeleteHandler = async (email) => {
        if (!window.confirm(`are you sure you want to delete ${email.label}`)) return
        try {
            await apis.emails.remove(email.id)
        } catch (e) {
           // helpers.errors.alertError(e)
        } finally {
            fetchEmails()
        }
    }

    const onCreatedHandler = () => {
        setCreatingEmail(false)
        fetchEmails()
    }

    useEffect(() => {
        fetchEmails()
    }, [])

    return (
        <>
            <Grid container>
                <Grid item xs justifyContent="center" className={'flexDisplay'}>
                    <ListCard
                        title={'Email'}
                        onAddClickHandler={onAddEmailClickHandler}
                    >
                        <EmailTable
                            data={emails}
                            onDeleteHandler={onEmailDeleteHandler}
                        />
                    </ListCard>
                </Grid>
            </Grid>
            { isCreatingEmail && 
                <EmailForm
                    title={'Creating Email'}
                    isOpen={isCreatingEmail}
                    setClose={() => { setCreatingEmail(false) }}
                    onCreated={onCreatedHandler}
                />
            }
        </>
    )
}

export default Email