import React, { useState } from 'react'
import {
    Grid, 
    TextField,
} from '@material-ui/core'

import FormDialog from '../FormDialog'
import useObject from '../../hook/useObject'

import helpers from '../../helper'
import apis from '../../api'

function EmailForm({
    title,
    isOpen,
    setClose,
    onCreated,
    email,
}) {
    const [ emailInfo, setEmailInfo ] = useObject(email ? email : {
        label: '',
        address: '',
    })

    const [ error, setError ] = useObject({})

    const [ submitting, setSubmitting ] = useState(false)

    const onLabelChangeHandler = (e) => {
        setEmailInfo('label', e.target.value)
        setError('label', '')
    }

    const onAddressChangeHandler = (e) => {
        setEmailInfo('address', e.target.value)
        setError('address', '')
    }

    const onSubmitHandler = async (e) => {
        e.preventDefault()
        setSubmitting(true)

        let hasError = false

        if (emailInfo.label === '') {
            setError('label', 'label cannot be empty')
            hasError = true 
        }
        if (emailInfo.address === '') {
            setError('address', 'address cannot be empty')
            hasError = true
        } else {
            const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
            if (!re.test(emailInfo.address.toLowerCase())) {
                setError('address', 'invalid email address')
                hasError = true
            }
        }

        if (hasError) {
            setSubmitting(false)
            return
        }

        try {
            const { data } = await apis.emails.create(
                emailInfo.label,
                emailInfo.address,
            )

            onCreated && onCreated(data)
        } catch (err) {
            helpers.errors.alertError(err)
        } finally {
            setSubmitting(false)
        }
    }

    return (
        <FormDialog
            title={title}
            isOpen={isOpen}
            setClose={setClose}
            isLoading={submitting}
            isUpdate={!!email}
            onSubmit={onSubmitHandler}
        >
            <Grid container spacing={3}>
                <Grid item xs={12} md={6} lg={6}>
                    <TextField
                        variant="outlined"
                        fullWidth
                        label="label"
                        value={emailInfo.label}
                        onChange={onLabelChangeHandler}
                        error={!!error.label}
                        helperText={error.label}
                    />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                    <TextField
                        variant="outlined"
                        fullWidth
                        label="address"
                        placeholder="example@email.com"
                        value={emailInfo.address}
                        onChange={onAddressChangeHandler}
                        error={!!error.address}
                        helperText={error.address}
                    />
                </Grid>
            </Grid>
        </FormDialog>
    )
}

export default EmailForm