import { useRef } from 'react'
import {
    Grid,
    Dialog,
    DialogContent,
    DialogActions,
    Typography,
    FormControl,
    Input,
    IconButton,
    Button,
} from '@material-ui/core'

import FileCopyIcon from '@material-ui/icons/FileCopy'

function APIKeyDisplay({
    apikey,
    isOpen,
    setClose,
}) {
    const apikeyRef = useRef(null)

    const copyToClipBoard = (e) => {
        apikeyRef.current.select()
        document.execCommand('copy')

        e.target.focus()
        alert('APIKey Copied')
    }

    return (
        <Dialog
            open={isOpen}
            onClose={setClose}
            fullWidth
            maxWidth="md"
        >
            <DialogContent>
                <Typography variant="h5" align="center">
                    Here is your API Key:
                </Typography>
                <Grid container fullWidth>
                    <Grid item xs={10} lg={11} md={11}>
                        <FormControl variant="outlined" readOnly fullWidth>
                            <Input
                                fullWidth
                                inputProps={{
                                    style: {
                                        fontSize: '50px',
                                        textAlign: 'center',
                                        color: 'black',
                                        paddingLeft: '50px',
                                    }
                                }}
                                inputRef={apikeyRef}
                                value={apikey.apikey}
                                onChange={()=>{}}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={2} lg={1} md={1} style={{ display: 'flex', alignItems: 'center' }}>
                        <IconButton size="medium">
                            <FileCopyIcon onClick={copyToClipBoard}/>
                        </IconButton>
                    </Grid>
                </Grid>
                <Typography variant="body1" align="center">
                    Please note taht you won't be able to see this key once you close this window.
                </Typography>
            </DialogContent>

            <DialogActions>
                <Button variant="container" color="primary" onClick={setClose}>
                    OK
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default APIKeyDisplay