import React from 'react'
import { useHistory } from 'react-router'
import { connect } from 'react-redux'
import {
    ProSidebar,
    Menu,
    MenuItem,
    SidebarHeader,
    SidebarContent,
} from 'react-pro-sidebar'
import { isMobile } from 'react-device-detect'
import { Link } from 'react-router-dom'

import { BiCalendarCheck } from 'react-icons/bi'
import { MdEmail } from 'react-icons/md'
import { GiHeartKey } from 'react-icons/gi'
import { AiOutlineUnlock } from 'react-icons/ai'
import { RiFilePaper2Line, RiUser3Fill } from 'react-icons/ri'
import { FaUserFriends } from 'react-icons/fa'

import background from '../resources/background.jpg'

import actions from '../store/actions'
import helpers from '../helper'
import apis from '../api'

function SideMenu({ 
    username, 
    userRole,
    collapsed, 
    toggleCollapsed, 
    dispatch,
 }) {
    const history = useHistory()
    if (isMobile) {
        return false
    }

    if (username === '') {
        return false
    }

    const logoutHandler = () => {
        apis.auth.logout()
        dispatch(actions.logout())
        history.push("/")
    }
    
    return (
        <ProSidebar
            image={background}
            collapsed={collapsed}
            rtl={false}
            breakPoint="md"
        >
            <SidebarHeader>
                <div
                    style={{
                        paddingLeft: '24px',
                        paddingTop: '24px',
                        textTransform: 'uppercase',
                        fontWeight: 'bold',
                        fontSize: 14,
                        letterSpacing: '1px',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        cursor: 'pointer'
                    }}
                    onClick={toggleCollapsed}
                >
                    WalMail
                </div>
                <div
                    style={{
                        paddingLeft: '24px',
                        paddingTop: '5px',
                        paddingBottom: '24px',
                        fontSize: 14,
                        whiteSpace: 'nowrap',
                    }}
                >
                    User: {username}
                </div>
            </SidebarHeader>
            <SidebarContent>
                <Menu iconShape="circle">
                    {helpers.users.isAdmin(userRole) && 
                        <MenuItem
                        icon={<FaUserFriends />}
                        >
                            Users
                            <Link to="/users" />
                        </MenuItem>
                    }
                    <MenuItem
                        icon={<RiUser3Fill />}
                    >
                        Account
                        <Link to="/account" />
                    </MenuItem>
                    <MenuItem
                        icon={<MdEmail />}
                    >
                        Email
                        <Link to="/email" />
                    </MenuItem>
                    <MenuItem
                        icon={<GiHeartKey />}
                    >
                        API Key
                        <Link to="/apikey" />
                    </MenuItem>
                    <MenuItem
                        icon={<BiCalendarCheck />}
                    >
                        Reminder
                        <Link to="/reminder" />
                    </MenuItem>
                    {helpers.users.isAdmin(userRole) && 
                        <MenuItem
                        icon={<RiFilePaper2Line />}
                        >
                            Log
                            <Link to="/log" />
                        </MenuItem>
                    }
                    {helpers.users.isAdmin(userRole) && 
                        <MenuItem
                        icon={<RiFilePaper2Line />}
                        >
                            Test Log
                            <Link to="/testlog" />
                        </MenuItem>
                    }
                    <MenuItem
                        icon={<AiOutlineUnlock />}
                        onClick={logoutHandler}
                    >
                        Logout
                    </MenuItem>
                </Menu>
            </SidebarContent>
        </ProSidebar>
    )
}

export default connect(state => ({
    username: state.auth.displayname,
    userRole: state.auth.roles,
})) (SideMenu)