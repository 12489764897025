import React, { useState } from 'react'
import {
    Grid,
} from '@material-ui/core'

import useObject from '../../hook/useObject'

import FormDialog from '../FormDialog'
import PasswordInput from '../field/PasswordInput'

import helpers from '../../helper'
import apis from '../../api'

function PasswordForm({ 
    isOpen,
    setClose,
    onCreated,
}) {
    const [ passwordInfo, setPasswordInfo ] = useObject({
        oldpassword: '',
        newpassword: '',
    })
    const [ error, setError ] = useObject('')

    const [ submitting, setSubmitting ] = useState(false)

    const onOldPasswordChangeHandler = (e) => {
        setPasswordInfo('oldpassword', e.target.value)
        setError('oldpassword', '')
    }

    const onNewPasswordChangeHandler = (e) => {
        setPasswordInfo('newpassword', e.target.value)
        setError('newpassword', '')
    }

    const onSubmitHandler = async (e) => {
        e.preventDefault()
        setSubmitting(true)

        let hasError = false

        if (passwordInfo.oldpassword === '') {
            setError('oldpassword', 'old password cannot be empty')
            hasError = true
        }
        if (passwordInfo.newpassword === '') {
            setError('newpassword', 'new password cannot be empty')
            hasError = true
        }
        if (passwordInfo.oldpassword === passwordInfo.newpassword) {
            setError('newpassword', 'new password cannot be the same as the old one')
            hasError = true
        }

        if (hasError) {
            setSubmitting(false)
            return
        }

        try {
            const response = await apis.users.change_password(
                passwordInfo.oldpassword, 
                passwordInfo.newpassword,
            )
            if (response.status !== 200) {
                alert(`Update error: ${response.data}`)
                return
            }

            onCreated && onCreated()
        } catch (err) {
            helpers.errors.alertError(err)
        } finally {
            setSubmitting(false)
        }

        setSubmitting(false)
    }

    return (
        <FormDialog
            title="Changing Password"
            isOpen={isOpen}
            setClose={setClose}
            isLoading={submitting}
            isUpdate={false}
            onSubmit={onSubmitHandler}
        >
            <Grid container spacing={3}>
                <Grid item xs={12} md={6} lg={6}>
                    <PasswordInput
                        label="old password"
                        password={passwordInfo.oldpassword}
                        setPassword={onOldPasswordChangeHandler}
                        errorMessage={error.oldpassword}
                    />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                    <PasswordInput
                        label="new password"
                        password={passwordInfo.newpassword}
                        setPassword={onNewPasswordChangeHandler}
                        errorMessage={error.newpassword}
                    />
                </Grid>
            </Grid>
        </FormDialog>
    )
}

export default PasswordForm