
import React, { useState, useEffect, useMemo } from 'react'
import {
    Grid,
} from '@material-ui/core'
import Pagination from '@material-ui/lab/Pagination'

import ListCard from '../components/ListCard'
import TestLogTable from '../components/table/TestLogTable'

import helpers from '../helper'
import apis from '../api'

function TestLog() {
    const [ logs, setLogs ] = useState([])
    const [ logCount, setCount ] = useState(0)
    const [ pageSize, setPageSize ] = useState(0)
    const [ page, setPage ] = useState(1)
    const pageCount = useMemo(() => {
        if (logCount === 0 || pageSize === 0) {
            return 0
        }
        return parseInt(logCount / pageSize) + 1
    }, [logCount, pageSize])
    
    const onPageChangeHandler = (event, value) => {
        setPage(value)
    }

    const fetchLogs = async () => { 
        try {
            const { data } = await apis.testlogs.list(page)
            setLogs(data.logs)
            setCount(data.count)
            setPageSize(data.size)
        } catch (e) {
            helpers.errors.alertError(e)
        }
    }

    const onRemoveAllClickHandler = async () => {
        const confirmed = window.confirm('Do you want to remove all test log?')
        if (confirmed) {
            try {
                const result = await apis.testlogs.clean()
                fetchLogs()
            } catch (e) {
                console.log(e)
            }
        }
    }

    useEffect(() => {
        fetchLogs()
    }, [page])

    useEffect(() => {
        fetchLogs()
    }, [])

    return (
        <>
            <Grid container>
                <Grid item xs justifyContent="center" className={'flexDisplay'}>
                    <ListCard
                        title={'Test Log'}
                        noCreateButton
                        onRemoveAllClickHandler={onRemoveAllClickHandler}
                    >
                        <Grid container>
                            <Grid item xs justifyContent="center" className={'flexDisplay'}>
                                <Pagination 
                                    count={pageCount} 
                                    page={page} 
                                    onChange={onPageChangeHandler}
                                    variant="outlined" />
                            </Grid>
                        </Grid>
                        <TestLogTable
                            data={logs}
                        />
                    </ListCard>
                </Grid>
            </Grid>
        </>
    )
}

export default TestLog