import actions_constant from "./actions_constant"

export const login = (id, username, displayname, roles) => {
    return {
        type: actions_constant.LOGIN_INFO,
        id,
        username,
        displayname,
        roles,
    }
}

export const displayname = (displayname) => {
    return {
        type: actions_constant.DISPLAY_CHANGE,
        displayname,
    }
}

export const logout = () => {
    return {
        type: actions_constant.LOGOUT,
    }
}

const actions = {
    login,
    displayname,
    logout,
}

export default actions