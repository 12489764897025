import { useState } from 'react'

function useToggle(defaultValue) {
    const [ value, setValue ] = useState(defaultValue)

    const toggleValue = () => {
        setValue(value => !value)
    }

    return [ value, toggleValue ]
}

export default useToggle