import getAxios from './axios'

const list = () => getAxios({
    url: '/email',
    method: 'GET',
})

const create = (label, address) => getAxios({
    url: '/email',
    method: 'POST',
    data: {
        label,
        address,
    },
})

const remove = (emailId) => getAxios({
    url: `/email/${emailId}`,
    method: 'DELETE',
})

const emails = {
    list,
    create,
    remove,
}

export default emails