import React from 'react'
import {
    Grid,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
} from '@material-ui/core'
import dayjs from 'dayjs'

import ViewTextField from '../field/ViewTextField'

function LogView({
    log,
    isOpen,
    setClose,
}) {
    return (
        <Dialog
            open={isOpen}
            onClose={setClose}
            scroll="paper"
            fullWidth
            maxWidth="lg"
        >
            <DialogTitle id="dialog-title">Log View</DialogTitle>
            <DialogContent>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={6} lg={6}>
                        <ViewTextField
                            label="subject"
                            value={log.subject}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                        <ViewTextField
                            label="type"
                            value={log.sendType}
                        />
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                        <ViewTextField
                            label="message"
                            value={log.message}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                        <ViewTextField
                            label="reciever"
                            value={log.reciever}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                        <ViewTextField
                            label="sent at"
                            value={dayjs(log.createdAt).format('YYYY-MM-DD HH:mm:ss')}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" color="primary" onClick={setClose}>
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    )
}


export default LogView