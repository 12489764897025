import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
    backgroundColor: {
        background: '#cdeaff',
        height: '100%',
    },
    topSpace: {
        paddingTop: '80px',
    }
}))

function BaseContext({ children }) {
    const classes = useStyles()
    return (
        <div className={classes.backgroundColor}>
            <div className={classes.topSpace}>
                {children}
            </div>
        </div>
    )
}

export default BaseContext