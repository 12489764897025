import React, { useState } from 'react'
import { useHistory } from 'react-router'
import { connect } from 'react-redux'
import {
    makeStyles,
    Grid,
    Card,
    CardActions,
    CardContent,
    Button,
    TextField,
} from '@material-ui/core'

import useObject from '../hook/useObject'

import actions from '../store/actions'
import helpers from '../helper'
import apis from '../api'

const useStyles = makeStyles({
    root: {
        maxWidth: 500,
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
})

function Login({ userId, dispatch }) {
    const classes = useStyles()
    const history = useHistory()
    const [ userInfo, setUserInfo ] = useObject({
        username: '',
        password: '',
    })
    const [ error, setError ] = useObject({})

    const [ submitting, setSubmitting ] = useState(false)
   
    const onUsernameChangeHandler = (e) => {
        setUserInfo('username', e.target.value)
        setError('username', '')

    }

    const onPasswordChangeHandler = (e) => {
        setUserInfo('password', e.target.value)
        setError('password', '')
    }

    const loginHandler = async (e) => {
        e.preventDefault()
        setSubmitting(true)

        let hasError = false

        if (userInfo.username === '') {
            setError('username', 'username cannot be empty')
            hasError = true
        }
        if (userInfo.password === '') {
            setError('password', 'password cannot be empty')
            hasError = true
        }

        if (hasError)  {
            setSubmitting(false)
            return
        }
        
        try {
            const { data } = await apis.auth.login(userInfo.username, userInfo.password)
            dispatch(actions.login(data.id, data.username, data.displayname, data.role))
            history.push('/account')
        } catch (err) {
            helpers.errors.loginError(err)
        } finally {
            setSubmitting(false)
        }
    }

    if (userId) {
        history.push('/account')
    }

    return (
        <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justify="center"
            style={{ minHeight: '100vh', paddingBottom: '100px' }}
        >
            <Grid item xs={6}>
                <form onSubmit={loginHandler} noValidate>
                    <Card className={classes.root}>
                        <CardContent>
                            <Grid 
                                container
                                spacing={2}
                                direction="column"
                                alignItems="center"
                                justify="center"  
                            >
                                <Grid item xs={12}>
                                    <TextField
                                        label="username"
                                        value={userInfo.username}
                                        onChange={onUsernameChangeHandler}
                                        error={!!error.username}
                                        helperText={error.username}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        type="password"
                                        label="password"
                                        value={userInfo.password}
                                        onChange={onPasswordChangeHandler}
                                        error={!!error.password}
                                        helperText={error.password}
                                    />
                                </Grid>
                            </Grid>
                        </CardContent>
                        <CardActions>
                            <Button 
                                variant="contained"
                                color="primary"
                                type="submit"
                                disabled={submitting}
                            >LOGIN</Button>
                        </CardActions>
                    </Card>
                </form>
            </Grid>
        </Grid>
    )
}

export default connect(state => ({
    userId: state.auth.id,
}))(Login)