import getAxios from './axios'

const list = (page) => getAxios({
    url: `/testlog/${page}`,
    method: 'GET',
})

const clean = () => getAxios({
    url: `/testlog`,
    method: 'DELETE',
})

const testlogs = {
    list,
    clean,
}

export default testlogs