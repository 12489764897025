import React, { useState } from 'react'
import {
    Grid,
    TextField
} from '@material-ui/core'

import FormDialog from '../FormDialog'
import ViewTextField from '../field/ViewTextField'
import Selectable from '../field/Selectable'
import PasswordInput from '../field/PasswordInput'
import useObject from '../../hook/useObject'

import helpers from '../../helper'
import apis from '../../api'

function UserForm({
    title,
    isOpen,
    setClose,
    onCreated,
    onEdited,
    user,
}) {
    const [ userInfo, setUserInfo ] = useObject(user ? user : {
        username: '',
        password: '',
        passwordconfirm: '',
        displayname: '',
        role: '',
        activated: true,
    })

    const [ error, setError ] = useObject({})

    const [ submitting, setSubmitting ] = useState(false)

    const isUpdate = !!user

    const onUsernameChangeHandler = (e) => {
        if (isUpdate) return
        setUserInfo('username', e.target.value)
        setError('username', '')
    }

    const onPasswordChangeHandler = (e) => {
        if (isUpdate) return
        setUserInfo('password', e.target.value)
        setError('password', '')
    }

    const onPasswordConfirmChangeHandler = (e) => {
        if (isUpdate) return
        setUserInfo('passwordconfirm', e.target.value)
        setError('passwordconfirm', '')
    }

    const onDisplaynameChangeHandler = (e) => {
        setUserInfo('displayname', e.target.value)
        setError('displayname', '')
    }

    const onRoleChangeHandler = (e) => {
        setUserInfo('role', e.target.value)
        setError('role', '')
    }

    const onActivatedChangeHandler = (e) => {
        setUserInfo('activated', e.target.value)
        setError('activated', '')
    }

    const onSubmitHandler = (e) => {
        e.preventDefault()
        if (isUpdate) {
            return onUpdateHandler()
        }
        onCreateHandler()
    }

    const onUpdateHandler = async () => {
        setSubmitting(true)

        let hasError = false
        
        if (userInfo.displayname === '') {
            setError('displayname', 'displayname cannot be empty')
            hasError = true
        }
        if (userInfo.role === '') {
            setError('role', 'role cannot be empty')
            hasError = true
        }

        if (userInfo.activated === '') {
            setError('activated', 'activated cannot be empty')
            hasError = true
        }

        if (hasError) {
            setSubmitting(false)
            return
        }

        setSubmitting(false)

        try {
            const { data } = await apis.users.update(userInfo.id, userInfo.displayname, userInfo.role, userInfo.activated)

            onEdited && onEdited(data)
        } catch (err) {
            helpers.errors.alertError(err)
        } finally {
            setSubmitting(false)
        }

    }

    const onCreateHandler = async () => {
        setSubmitting(true)

        let hasError = false

        if (userInfo.username === '') {
            setError('username', 'username cannot be empty')
            hasError = true
        }
        if (userInfo.displayname === '') {
            setError('displayname', 'displayname cannot be empty')
            hasError = true
        }
        if (userInfo.password === '') {
            setError('password', 'password cannot be empty')
            hasError = true
        }
        else {
            if (userInfo.password.length < 6) {
                setError('password', 'password should be longer than 6 letters')
                hasError = true
            } else if (userInfo.password !== userInfo.passwordconfirm) {
                setError('passwordconfirm', 'confirm password is not the same')
                hasError = true
            }
        }
        if (userInfo.role === '') {
            setError('role', 'role cannot be empty')
            hasError = true
        }

        if (userInfo.activated === '') {
            setError('activated', 'activated cannot be empty')
            hasError = true
        }

        if (hasError) {
            setSubmitting(false)
            return
        }

        try {
            const response = await apis.users.register(userInfo.username, userInfo.password, userInfo.displayname, userInfo.role, userInfo.activated)

            if (response.status !== 200 ) {
                alert(`Create error ${response.data}`)
                return
            }

            onCreated && onCreated()
        } catch (err) {
            alert(`Unexpected error: ${err}`)
        } finally {
            setSubmitting(false)
        }
    }

    return (
        <FormDialog
            title={title}
            isOpen={isOpen}
            setClose={setClose}
            isLoading={submitting}
            isUpdate={!!user}
            onSubmit={onSubmitHandler}
        >
            <Grid container spacing={3}>
                <Grid item xs={12} md={6} lg={6}>
                    { isUpdate ? (
                        <ViewTextField 
                            label="username (cannot change)"
                            value={userInfo.username}
                        />
                    ) : (
                        <TextField
                            variant="outlined"
                            fullWidth
                            label="username"
                            value={userInfo.username}
                            onChange={onUsernameChangeHandler}
                            error={!!error.username}
                            helperText={error.username}
                        />
                    )}
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                    <TextField
                        variant="outlined"
                        fullWidth
                        label="displayname"
                        value={userInfo.displayname}
                        onChange={onDisplaynameChangeHandler}
                        error={!!error.displayname}
                        helperText={error.displayname}
                    />
                </Grid>
                { !isUpdate && 
                    <>
                        <Grid item xs={12} md={6} lg={6}>
                            <PasswordInput
                                label="password"
                                password={userInfo.password}
                                setPassword={onPasswordChangeHandler}
                                errorMessage={error.password}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                            <PasswordInput
                                label="confirm password"
                                password={userInfo.passwordconfirm}
                                setPassword={onPasswordConfirmChangeHandler}
                                errorMessage={error.passwordconfirm}
                            />
                        </Grid>
                    </>
                }
                
                <Grid item xs={12} md={6} lg={6}>
                    <Selectable
                        label="role"
                        value={userInfo.role}
                        onValueChange={onRoleChangeHandler}
                        errorMessage={error.role}
                        defaultSelectaValue={''}
                        defaultSelectText={''}
                        list={[
                            { value: 'admin', label: 'Admin' },
                            { value: 'user', label: 'User' },
                        ]}
                        valueKey={'value'}
                        textKey={'label'}
                    />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                    <Selectable
                        label="activated"
                        value={userInfo.activated}
                        onValueChange={onActivatedChangeHandler}
                        errorMessage={error.activated}
                        noDefault
                        list={[
                            { value: true, label: 'Yes' },
                            { value: false, label: 'No' },
                        ]}
                        valueKey={'value'}
                        textKey={'label'}
                    />
                </Grid>
            </Grid>
        </FormDialog>
    )
}

export default UserForm