
import React, { useState, useEffect } from 'react'
import {
    Grid,
} from '@material-ui/core'

import ListCard from '../components/ListCard'
import APIKeyForm from '../components/dialog/APIKeyForm'
import APIKeyDisplay from '../components/dialog/APIKeyDisplay'
import APIKeyTable from '../components/table/APIKeyTable'

import helpers from '../helper'
import apis from '../api'

function APIKey() {
    const [ emails, setEmails ] = useState([])
    const [ apikeys, setKeys ] = useState([])
    const [ isCreatingKey, setCreatingKey ] = useState(false)
    const [ createdKey, setCreatedKey ] = useState(null)

    const fetchEmails = async () => { 
        try {
            const { data } = await apis.emails.list()
            setEmails(data)
        } catch (e) {
            helpers.errors.alertError(e)
        }
    }

    const fetchKeys = async () => {
        try {
            const { data } = await apis.apikeys.list()
            setKeys(data)
        } catch (e) {
            helpers.errors.alertError(e)
        }
    }

    const onAddKeyClickHandler = () => {
        setCreatingKey(true)
    }

    const onKeyDeleteHandler = async (apikey) => {
        if (!window.confirm(`are you sure you want to delete ${apikey.label}`)) return
        try {
            await apis.apikeys.remove(apikey.id)
        } catch (e) {
            //helpers.errors.alertError(e)
        } finally {
            fetchKeys()
        }
    }

    const onCreatedHandler = (created) => {
        setCreatingKey(false)
        setCreatedKey(created)
        fetchKeys()
    }

    const removeCreatedKey = () => {
        setCreatedKey(null)
    }

    useEffect(() => {
        fetchEmails()
        fetchKeys()
    }, [])

    return (
        <>
            <Grid container>
                <Grid item xs justifyContent="center" className={'flexDisplay'}>
                    <ListCard
                        title={'APIKey'}
                        onAddClickHandler={onAddKeyClickHandler}
                    >
                        <APIKeyTable
                            data={apikeys}
                            emails={emails}
                            onDeleteHandler={onKeyDeleteHandler}
                        />
                    </ListCard>
                </Grid>
            </Grid>
            { isCreatingKey && 
                <APIKeyForm
                    title={'Creating APIKey'}
                    isOpen={isCreatingKey}
                    setClose={() => { setCreatingKey(false) }}
                    emails={emails}
                    onCreated={onCreatedHandler}
                />
            }
            { createdKey && 
                <APIKeyDisplay
                    apikey={createdKey}
                    isOpen={!!createdKey}
                    setClose={removeCreatedKey}
                />
            }
        </>
    )
}

export default APIKey