import getAxios from './axios'
import helpers from '../helper'

const list = (page) => getAxios({
    url: `/reminder/${page}`,
    method: 'GET',
})

const create = (label, subject, message, remindBefore, remindInterval, remindAt, emailId) => getAxios({
    url: '/reminder',
    method: 'POST',
    data: {
        label,
        subject,
        message,
        remindBefore,
        remindInterval,
        remindAt: helpers.time.toRFC3339Date(remindAt),
        emailId,
    }
})

const update = (reminderId, label, subject, message, remindBefore, remindInterval, remindAt, emailId) => getAxios({
    url: `/reminder/${reminderId}`,
    method: 'PATCH',
    data: {
        label,
        subject,
        message,
        remindBefore,
        remindInterval,
        remindAt: helpers.time.toRFC3339Date(remindAt),
        emailId,
    }
})

const remove = (reminderId) => getAxios({
    url: `/reminder/${reminderId}`,
    method: 'DELETE',
})

const reminders = {
    list,
    create,
    update,
    remove,
}

export default reminders