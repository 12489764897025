const alertError = (e) => {
    if (e.response) {
        if (e.response.data && e.response.status) {
            alert(`error status code ${e.response.status} with message: ${e.response.data}`)
        } else if (e.response.status) {
            alert(`error status code ${e.response.status}`)
        } else if (e.response.data) {
            alert(`error message: ${e.response.data}`)
        } else {
            alert(`unexpected error ${e.response}`)
        }
    } else {
        alert(`unexpected error ${e}`)
    }
}

const loginError = (e) => {
    if (e.response) {
        if (e.response.status === 401) {
            alert('username or password incorrect')
        }
        else {
            alertError(e)
        }
    }
    else {
        alertError(e)
    }
}

const errors = {
    alertError,
    loginError,
}

export default errors