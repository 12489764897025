import React from 'react'
import {
    TableCell,
} from '@material-ui/core'
import dayjs from 'dayjs'

import BaseTable from '../BaseTable'

function ReminderTable({
    data,
    emails,
    onEditHandler,
    onViewHandler,
    onDeleteHandler,
}) {
    const getEmailLabel = (id) => {
        let email = emails.find(s => s.id === id)
        if (email) return email.label
        return ''
    }

    const reminderRow = (reminder) => {
        return (
            <>
                <TableCell align="center">{reminder.label}</TableCell>
                <TableCell align="center">{reminder.subject}</TableCell>
                <TableCell align="center">{reminder.remindAt ? dayjs(reminder.remindAt).format('YYYY-MM-DD'): '-'}</TableCell>
                <TableCell align="center">{reminder.remindBefore} day{reminder.remindBefore !== 1 ? 's' : ''}</TableCell>
                <TableCell align="center">{reminder.emailId ? getEmailLabel(reminder.emailId) : '-'}</TableCell>
                <TableCell align="center">{reminder.userName}</TableCell>
            </>
        )
    }

    return (
        <BaseTable
            withIndex
            headers={['label', 'subject', 'remindAt', 'before', 'email', 'created by']}
            data={data}
            getRow={reminderRow}
            hasAction
            onEditHandler={onEditHandler}
            onViewHandler={onViewHandler}
            onDeleteHandler={onDeleteHandler}
        />
    )
}

export default ReminderTable