import React, { useMemo } from 'react'
import {
    makeStyles,
    IconButton,
    CardHeader,
    CardContent,
    Card,
} from '@material-ui/core'

import AddCircleIcon from '@material-ui/icons/AddCircle'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'

const useStyles = makeStyles({
    cardRoot: {
        width: '90%',
    },
    flexDisplay: {
        display: 'flex',
    },
})

function ListCard({
    title,
    noCreateButton,
    onAddClickHandler,
    onRemoveAllClickHandler,
    children,
}) {
    const classes = useStyles()

    const actionButtons = useMemo(() => {
        let result = []
        if (!noCreateButton && onAddClickHandler) {
            result.push(
                <IconButton aria-label="add" onClick={onAddClickHandler}>
                    <AddCircleIcon />
                </IconButton>
            )
        }
        if (onRemoveAllClickHandler) {
            result.push(
                <IconButton aria-label="add" onClick={onRemoveAllClickHandler}>
                    <DeleteOutlineIcon />
                </IconButton>
            )
        }
        return result
    }, [noCreateButton, onAddClickHandler, onRemoveAllClickHandler])

    return (
        <Card className={classes.cardRoot}>
            <CardHeader
                    title={title}
                    action={actionButtons}
                />
            <CardContent>
                {children}
            </CardContent>
        </Card>
    )
}

export default ListCard