import auth from './auth'
import users from './user'
import emails from './email'
import apikeys from './apikey'
import reminders from './reminder'
import logs from './log'
import testlogs from './testlog'

const apis = {
    auth,
    users,
    emails,
    apikeys,
    reminders,
    logs,
    testlogs,
}

export default apis