import dayjs from 'dayjs'

// convert to 2006-01-02T15:04:05Z07:00 format
const toRFC3339Date = (date) => {
    const dateOnly = dayjs(date).format('YYYY-MM-DD')
    return dateOnly + 'T00:00:00Z'
}

const time = {
    toRFC3339Date,
}

export default time