
import React, { useState, useEffect, useMemo } from 'react'
import {
    Grid,
} from '@material-ui/core'
import Pagination from '@material-ui/lab/Pagination'

import ListCard from '../components/ListCard'
import LogView from '../components/dialog/LogView'
import LogTable from '../components/table/LogTable'

import helpers from '../helper'
import apis from '../api'

function Log() {
    const [ logs, setLogs ] = useState([])
    const [ logCount, setCount ] = useState(0)
    const [ pageSize, setPageSize ] = useState(0)
    const [ page, setPage ] = useState(1)
    const [ viewingLog, setViewingLog ] = useState(null)
    const pageCount = useMemo(() => {
        if (logCount === 0 || pageSize === 0) {
            return 0
        }
        return parseInt(logCount / pageSize) + 1
    }, [logCount, pageSize])
    
    const onPageChangeHandler = (event, value) => {
        setPage(value)
    }

    const fetchLogs = async () => { 
        try {
            const { data } = await apis.logs.list(page)
            setLogs(data.logs)
            setCount(data.count)
            setPageSize(data.size)
        } catch (e) {
            helpers.errors.alertError(e)
        }
    }

    const onLogViewHandler = (log) => {
        setViewingLog(log)
    }

    useEffect(() => {
        fetchLogs()
    }, [page])

    useEffect(() => {
        fetchLogs()
    }, [])

    return (
        <>
            <Grid container>
                <Grid item xs justifyContent="center" className={'flexDisplay'}>
                    <ListCard
                        title={'Log'}
                        noCreateButton
                    >
                        <Grid container>
                            <Grid item xs justifyContent="center" className={'flexDisplay'}>
                                <Pagination 
                                    count={pageCount} 
                                    page={page} 
                                    onChange={onPageChangeHandler}
                                    variant="outlined" />
                            </Grid>
                        </Grid>
                        <LogTable
                            data={logs}
                            onViewHandler={onLogViewHandler}
                        />
                    </ListCard>
                </Grid>
            </Grid>
            { viewingLog && 
                <LogView
                    log={viewingLog}
                    isOpen={!!viewingLog}
                    setClose={() => setViewingLog(null) }
                />
            }
        </>
    )
}

export default Log