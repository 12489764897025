import React, { useState } from 'react'
import {
    Grid,
    TextField,
} from '@material-ui/core'

import FormDialog from '../FormDialog'
import ViewTextField from '../field/ViewTextField'

import helpers from '../../helper'
import apis from '../../api'

function DisplaynameForm({ 
    displayname,
    isOpen,
    setClose,
    onCreated,
}) {
    const [ newDisplayname, setDisplayname ] = useState(displayname)
    const [ error, setError ] = useState('')

    const [ submitting, setSubmitting ] = useState(false)

    const onDisplaynameChangeHandler = (e) => {
        setDisplayname(e.target.value)
        setError('')
    }

    const onSubmitHandler = async (e) => {
        e.preventDefault()
        setSubmitting(true)

        let hasError = false

        if ( newDisplayname === '') {
            setError('display name cannot be empty')
            hasError = true
        } else if ( displayname === newDisplayname ) {
            setError('new display name cannot be the same as before')
            hasError = true
        }

        if (hasError) {
            setSubmitting(false)
            return
        }

        try {
            const response = await apis.users.update_displayname(
                newDisplayname,
            )
            if (response.status !== 200) {
                alert(`Update error: ${response.data}`)
                return
            }

            onCreated && onCreated(newDisplayname)
        } catch (err) {
            helpers.errors.alertError(err)
        } finally {
            setSubmitting(false)
        }

        setSubmitting(false)
    }

    return (
        <FormDialog
            title="Changing Displayname"
            isOpen={isOpen}
            setClose={setClose}
            isLoading={submitting}
            isUpdate={false}
            onSubmit={onSubmitHandler}
        >
            <Grid container spacing={3}>
                <Grid item xs={12} md={6} lg={6}>
                    <ViewTextField
                        label="old displayname"
                        value={displayname}
                    />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                    <TextField
                        variant="outlined"
                        fullWidth
                        label="new displayname"
                        value={newDisplayname}
                        onChange={onDisplaynameChangeHandler}
                        error={!!error}
                        helperText={error}
                    />
                </Grid>
            </Grid>
        </FormDialog>
    )
}

export default DisplaynameForm