import React from 'react'
import {
    TableCell,
} from '@material-ui/core'
import dayjs from 'dayjs'

import BaseTable from '../BaseTable'

function APIKeyTable({
    data,
    emails,
    onDeleteHandler,
}) {
    const getEmailLabel = (id) => {
        let email = emails.find(s => s.id === id)
        if (email) return email.label
        return ''
    }

    const apikeyRow = (apikey) => {
        return (
            <>
                <TableCell align="center">{apikey.label}</TableCell>
                <TableCell align="center">{apikey.type}</TableCell>
                <TableCell align="center">{apikey.expiredAt ? dayjs(apikey.expiredAt).format('YYYY-MM-DD'): '-'}</TableCell>
                <TableCell align="center">{apikey.associatedEmail ? getEmailLabel(apikey.associatedEmail) : '-'}</TableCell>
                <TableCell align="center">{apikey.userName}</TableCell>
            </>
        )
    }

    return (
        <BaseTable
            withIndex
            headers={['label', 'type', 'expiredAt', 'email', 'created by']}
            data={data}
            getRow={apikeyRow}
            hasAction
            onDeleteHandler={onDeleteHandler}
        />
    )
}

export default APIKeyTable