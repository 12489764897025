import React, { useState } from 'react'
import { connect } from 'react-redux'
import {
    Grid,
} from '@material-ui/core'

import ListCard from '../components/ListCard'
import DisplaynameForm from '../components/account/DisplaynameForm'
import PasswordForm from '../components/account/PasswordForm'
import InfoTable from '../components/account/InfoTable'

import actions from '../store/actions'

function Account({
    username,
    displayname,
    userRole,
    dispatch,
}) {
    const [ isChangingDisplayname, setChangingDisplayname ] = useState(false)
    const [ isChangingPassword, setChangingPassword ] = useState(false)

    const onChangeDisplaynameHandler = () => {
        setChangingDisplayname(true)
    }

    const onChangePasswordHandler = () => {
        setChangingPassword(true)
    }

    const onDisplaynameChanged = (newdisplay) => {
        setChangingDisplayname(false)
        dispatch(actions.displayname(newdisplay))
    }

    const onPasswordChanged = (password) => {
        setChangingPassword(false)
        alert('password changed')
    }

    return (
        <>
            <Grid container>
                <Grid item xs justifyContent="center" className={'flexDisplay'}>
                    <ListCard
                        title={'Account Information'}
                        noCreateButton
                    >
                        <InfoTable
                            username={username}
                            displayname={displayname}
                            role={userRole}
                            onChangeDisplaynameClick={onChangeDisplaynameHandler}
                            onChangePasswordClick={onChangePasswordHandler}
                        />
                    </ListCard>
                </Grid>
            </Grid>
            { isChangingDisplayname && 
                <DisplaynameForm 
                    displayname={displayname}
                    isOpen={isChangingDisplayname}
                    setClose={() => { setChangingDisplayname(false) }}
                    onCreated={onDisplaynameChanged}
                />
            }
            { isChangingPassword &&
                <PasswordForm
                    isOpen={isChangingPassword}
                    setClose={() => { setChangingPassword(false) }}
                    onCreated={onPasswordChanged}
                />
            }
        </>
    )
}

export default connect(state => ({
    username: state.auth.username,
    displayname: state.auth.displayname,
    userRole: state.auth.roles,
})) (Account)