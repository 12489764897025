import React from 'react'
import {
    Grid,
    IconButton,
} from '@material-ui/core'
import DateFnsUtils from '@date-io/date-fns'
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers'
import dayjs from 'dayjs'

import CancelIcon from '@material-ui/icons/Cancel'

function NullableDatePicker({
    label,
    value,
    onValueChange,
    errorMessage
}) {
    const clearDate = () => {
        onValueChange(null)
    }

    return (
        <Grid container fullWidth>
            <Grid item xs={10} lg={11} md={11}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                        required={false}
                        label={label}
                        format="MM/dd/yyyy"
                        value={value}
                        onChange={onValueChange}
                        inputVariant="outlined"
                        helperText={errorMessage}
                        error={!!errorMessage}
                        minDate={dayjs()}
                        fullWidth
                        InputLabelProps={{
                            shrink: !!value,
                        }}
                    />
                </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={2} lg={1} md={1}>
                <IconButton aria-label="cancel" onClick={clearDate}>
                    <CancelIcon />
                </IconButton>
            </Grid>
        </Grid>
    )
}

export default NullableDatePicker