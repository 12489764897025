import React from 'react'
import { useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import {
    makeStyles,
    AppBar,
    Toolbar,
    IconButton,
    Menu,
    MenuItem,
} from '@material-ui/core'
import { isMobile } from 'react-device-detect'

import MenuIcon from '@material-ui/icons/Menu'

import actions from '../store/actions'
import helpers from '../helper'
import apis from '../api'

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      position: 'absolute',
      width: '100%',
    },
    abRoot: {
      backgroundColor: '#4e5e5e',
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
    },
}))

//
function MobileTopBar({ 
    username,
    userRole,
    dispatch,
 }) {
    const history = useHistory()
    const classes = useStyles()

    const [anchorEl, setAnchorEl] = React.useState(null)
    const open = Boolean(anchorEl)
    

    if (!isMobile) {
        return false
    }  

    if (username === '') {
        return false
    }

    const toLocation = (path) => {
        history.replace(path)
    }

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    }

    const handleClose = () => {
        setAnchorEl(null);
    }

    const logoutHandler = () => {
        apis.auth.logout()
        dispatch(actions.logout())
        history.push("/")
    }

    return (
        <div className={classes.root}>
            <AppBar 
                classes={{ 
                    root: classes.abRoot,  
                }}
                position="static">
                <Toolbar>
                    <IconButton
                        aria-label="account of current user"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        onClick={handleMenu}
                        color="inherit"
                    >
                        <MenuIcon />
                    </IconButton>
                    <Menu
                        id="menu-appbar"
                        anchorEl={anchorEl}
                        keepMounted
                        open={open}
                        onClose={handleClose}
                    >
                        {helpers.users.isAdmin(userRole) && 
                            <MenuItem onClick={() => toLocation('/users')}>
                                Users
                            </MenuItem>
                        }
                        <MenuItem onClick={() => toLocation('/account')}>
                            Account
                        </MenuItem>
                        <MenuItem onClick={() => toLocation('/email')}>
                            Email
                        </MenuItem>
                        <MenuItem onClick={() => toLocation('/apikey')}>
                            APIKey
                        </MenuItem>
                        <MenuItem onClick={() => toLocation('/reminder')}>
                            Reminder
                        </MenuItem>
                        {helpers.users.isAdmin(userRole) && 
                            <MenuItem onClick={() => toLocation('/log')}>
                                Log
                            </MenuItem>
                        }
                        <MenuItem onClick={logoutHandler}>
                            Logout
                        </MenuItem>
                    </Menu>
            </Toolbar>
            </AppBar>
        </div>
    )
}

export default connect(state => ({
    username: state.auth.username,
    userRole: state.auth.roles,
})) (MobileTopBar)