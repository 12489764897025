import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import './index.css'
import reportWebVitals from './reportWebVitals'

import store from './store'
import apis from './api'
import actions from './store/actions'

import App from './App'

apis.auth.me().then(({ data }) => {
  store.dispatch(actions.login(data.id, data.usename, data.displayname, data.role ))
}).catch(err => {
  store.dispatch(actions.logout())
}).finally(() => {
  ReactDOM.render(
    <React.StrictMode>
      <Provider store={store}>
        <App />
      </Provider>
    </React.StrictMode>,
    document.getElementById('root'),
  )
})



// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
