import React, { useState, useEffect } from 'react'
import {
    Grid,
} from '@material-ui/core'

import ListCard from '../components/ListCard'
import UserForm from '../components/dialog/UserForm'
import UserTable from '../components/table/UserTable'

import helpers from '../helper'
import apis from '../api'

function User() {
    const [ users, setUsers ] = useState([])
    const [ isCreatingUser, setCreatingUser ] = useState(false)
    const [ editingUser, setEditingUser ] = useState(null)

    const fetchUsers = async () => {
        try {
            const { data } = await apis.users.list()
            setUsers(data)
        } catch (e) {
            helpers.errors.alertError(e)
        }
    }

    const onAddUserClickHandler = () => {
        setCreatingUser(true)
    }

    const onUserEditHandler = (user) => {
        setEditingUser(user)
    }

    const onUserDeletHandler = async (user) => {
        if (!window.confirm(`are you sure you want to delete ${user.username}`)) return
        try {
            await apis.users.remove(user.id)
            fetchUsers()
        } catch (e) {
            //helpers.errors.alertError(e)
        } finally {
            fetchUsers()
        }
    }

    const onCreatedHandler = () => {
        setCreatingUser(false)
        fetchUsers()
    }

    const onEditedHandler = () => {
        setEditingUser(null)
        fetchUsers()
    }

    useEffect(() => {
        fetchUsers()
    }, [])

    return (
        <>
            <Grid container>
                <Grid item xs justifyContent="center" className={'flexDisplay'}>
                    <ListCard
                        title={'User'}
                        onAddClickHandler={onAddUserClickHandler}
                    >
                        <UserTable
                            data={users}
                            onEditHandler={onUserEditHandler}
                            onDeleteHandler={onUserDeletHandler}
                        />
                    </ListCard>
                </Grid>
            </Grid>
            { isCreatingUser && 
                <UserForm
                    title={'Creating User'}
                    isOpen={isCreatingUser}
                    setClose={() => { setCreatingUser(false) }}
                    onCreated={onCreatedHandler}
                />
            }
            { editingUser && 
                <UserForm
                    title={'Editing User'}
                    isOpen={!!editingUser}
                    setClose={() => { setEditingUser(null) }}
                    onEdited={onEditedHandler}
                    user={editingUser}
                />
            }
        </>
    )
}

export default User