import React, { useState } from 'react'
import {
    Grid,
    TextField,
} from '@material-ui/core'
import dayjs from 'dayjs'

import NullableDatePicker from '../field/NullableDatePicker'
import Selectable from '../field/Selectable'
import FormDialog from '../FormDialog'
import useObject from '../../hook/useObject'

import helpers from '../../helper'
import apis from '../../api'

function ReminderForm({
    title,
    isOpen,
    setClose,
    onCreated,
    onEdited,
    emails,
    reminder,
}) {
    const [ reminderInfo, setReminderInfo ] = useObject(reminder ? reminder : {
        label: '',
        subject: '',
        message: '',
        remindBefore: 1,
        remindInterval: 1,
        remindAt: dayjs(),
        emailId: '',
    })

    const [ error, setError ] = useObject({})

    const [ submitting, setSubmitting ] = useState(false)

    const isUpdate = !!reminder

    const onLabelChangeHandler = (e) => {
        setReminderInfo('label', e.target.value)
        setError('label', '')
    }

    const onSubjectChangeHandler = (e) => {
        setReminderInfo('subject', e.target.value)
        setError('subject', '')
    }

    const onMessageChangeHandler = (e) => {
        setReminderInfo('message', e.target.value)
        setError('message', '')
    }

    const onReminderBeforeChangeHandler = (e) => {
        setReminderInfo('remindBefore', e.target.value)
        setError('remindBefore', '')
    }

    const onReminderIntervalChangeHandler = (e) =>{
        setReminderInfo('remindInterval', e.target.value)
        setError('remindInterval', '')
    }
    
    const onRemindAtChangeHandler = (date) => {
        setReminderInfo('remindAt', date)
        setError('remindAt', '')
    }

    const onEmailIdChangeHandler = (e) => {
        setReminderInfo('emailId', e.target.value)
        setError('emailId', '')
    }

    const onSubmitHandler = (e) => {
        e.preventDefault()
        if (isUpdate) {
            return onUpdateHandler()
        }
        onCreateHandler()
    }

    const onUpdateHandler = async () => {
        setSubmitting(true)

        let hasError = false

        if (reminderInfo.label === '') {
            setError('label', 'label cannot be empty')
            hasError = true
        }
        if (reminderInfo.subject === '') {
            setError('subject', 'subject cannot be empty')
            hasError = true
        }
        if (reminderInfo.message === '') {
            setError('message', 'message cannot be empty')
            hasError = true
        }
        if (reminderInfo.remindBefore === '') {
            setError('remindBefore', 'remind before cannot be empty')
            hasError = true
        }
        if (reminderInfo.remindInterval === '') {
            setError('remindInterval', 'remind interval cannot be empty')
            hasError = true
        }
        if (reminderInfo.remindAt === null) {
            setError('remindAt', 'remind date cannot be empty')
            hasError = true
        }
        if (reminderInfo.label === '') {
            setError('emailId', 'email cannot be empty')
            hasError = true
        }

        if (hasError) {
            setSubmitting(false)
            return
        }

        try {
            const { data } = await apis.reminders.update(
                reminderInfo.id,
                reminderInfo.label,
                reminderInfo.subject,
                reminderInfo.message,
                reminderInfo.remindBefore,
                reminderInfo.remindInterval,
                reminderInfo.remindAt,
                reminderInfo.emailId,
            )
            
            onEdited && onEdited(data)
            
        } catch (err) {
            helpers.errors.alertError(err)
        } finally {
            setSubmitting(false)
        }
    }

    const onCreateHandler = async () => {
        setSubmitting(true)

        let hasError = false

        if (reminderInfo.label === '') {
            setError('label', 'label cannot be empty')
            hasError = true
        }
        if (reminderInfo.subject === '') {
            setError('subject', 'subject cannot be empty')
            hasError = true
        }
        if (reminderInfo.message === '') {
            setError('message', 'message cannot be empty')
            hasError = true
        }
        if (reminderInfo.remindBefore === '') {
            setError('remindBefore', 'remind before cannot be empty')
            hasError = true
        }
        if (reminderInfo.remindInterval === '') {
            setError('remindInterval', 'remind interval cannot be empty')
            hasError = true
        }
        if (reminderInfo.remindAt === null) {
            setError('remindAt', 'remind date cannot be empty')
            hasError = true
        }
        if (reminderInfo.label === '') {
            setError('emailId', 'email cannot be empty')
            hasError = true
        }

        if (hasError) {
            setSubmitting(false)
            return
        }

        try {
            const { data } = await apis.reminders.create(
                reminderInfo.label,
                reminderInfo.subject,
                reminderInfo.message,
                reminderInfo.remindBefore,
                reminderInfo.remindInterval,
                reminderInfo.remindAt,
                reminderInfo.emailId,
            )

            onCreated && onCreated(data)
            
        } catch (err) {
            alert(`Unexpected error: ${err}`)
        } finally {
            setSubmitting(false)
        }
    }

    return (
        <FormDialog
            title={title}
            isOpen={isOpen}
            setClose={setClose}
            isLoading={submitting}
            isUpdate={!!reminder}
            onSubmit={onSubmitHandler}
        >
            <Grid container spacing={3}>
                <Grid item xs={12} md={6} lg={6}>
                    <TextField
                        variant="outlined"
                        fullWidth
                        label="label"
                        value={reminderInfo.label}
                        onChange={onLabelChangeHandler}
                        error={!!error.label}
                        helperText={error.label}
                    />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                    <TextField
                        variant="outlined"
                        fullWidth
                        label="subject"
                        value={reminderInfo.subject}
                        onChange={onSubjectChangeHandler}
                        error={!!error.subject}
                        helperText={error.subject}
                    />
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                    <TextField
                        variant="outlined"
                        fullWidth
                        label="message"
                        value={reminderInfo.message}
                        onChange={onMessageChangeHandler}
                        error={!!error.message}
                        helperText={error.message}
                    />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                    <NullableDatePicker
                        label={'remind date'}
                        value={reminderInfo.remindAt}
                        onValueChange={onRemindAtChangeHandler}
                        errorMessage={error.remindAt}
                    />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                    <Selectable
                        label="remind before"
                        value={reminderInfo.remindBefore}
                        onValueChange={onReminderBeforeChangeHandler}
                        errorMessage={error.remindBefore}
                        noDefault
                        list={[
                            { value: 0, label: '0 day' }, 
                            { value: 1, label : '1 day' },
                            { value: 2, label : '2 days' },
                            { value: 3, label : '3 days' },
                            { value: 4, label : '4 days' },
                            { value: 5, label : '5 days' },
                            { value: 6, label : '6 days' },
                            { value: 7, label : '7 days' },
                            { value: 8, label : '8 days' },
                            { value: 9, label : '9 days' },
                            { value: 10, label : '10 days' },
                        ]}
                        valueKey={'value'}
                        textKey={'label'}
                    />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                    <Selectable
                        label="remind interval"
                        value={reminderInfo.remindInterval}
                        onValueChange={onReminderIntervalChangeHandler}
                        errorMessage={error.remindInterval}
                        noDefault
                        list={[
                            { value: 1, label : '1 day' },
                            { value: 2, label : '2 days' },
                            { value: 3, label : '3 days' },
                            { value: 4, label : '4 days' },
                            { value: 5, label : '5 days' },
                            { value: 6, label : '6 days' },
                            { value: 7, label : '7 days' },
                            { value: 8, label : '8 days' },
                            { value: 9, label : '9 days' },
                            { value: 10, label : '10 days' },
                        ]}
                        valueKey={'value'}
                        textKey={'label'}
                    />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                    <Selectable
                        label="email"
                        value={reminderInfo.emailId}
                        onValueChange={onEmailIdChangeHandler}
                        errorMessage={error.emailId}
                        defaultSelectValue={''}
                        defaultSelectText={''}
                        list={emails}
                        valueKey={'id'}
                        textKey={'label'}
                    />
                </Grid>
            </Grid>
        </FormDialog>
    )
}

export default ReminderForm