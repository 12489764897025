import React from 'react'
import { 
    FormControl,
    FormHelperText,
    InputLabel,
    OutlinedInput,
    IconButton,
    InputAdornment,
} from '@material-ui/core'

import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'

import useToggle from '../../hook/useToggle'

function PasswordInput({
    label,
    password,
    setPassword,
    errorMessage,
}) {
    const [ showPassword, toggleShowPassword ] = useToggle(false)

    return (
        <FormControl variant="outlined" fullWidth>
            <InputLabel htmlFor={'password-'+label} error={!!errorMessage}>{label}</InputLabel>
            <OutlinedInput
                label={label}
                id={'password-'+label}
                type={showPassword ? 'text' : 'password'}
                fullWidth
                value={password}
                onChange={setPassword}
                error={!!errorMessage}
                endAdornment={
                    <InputAdornment position="end">
                        <IconButton onClick={toggleShowPassword}>
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                    </InputAdornment>
                }
            />
            {errorMessage && (
                <FormHelperText htmlFor={'password-' + label} error={!!errorMessage}>
                    {errorMessage}
                </FormHelperText>
            )}
        </FormControl>
    )
}

export default PasswordInput