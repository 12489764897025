import time from './time'
import users from './user'
import errors from './error'

const helpers = {
    time,
    users,
    errors,
}

export default helpers