import { createTheme } from '@material-ui/core/styles'

const theme = createTheme({
    typography: {
        fontFamily: ['Nunito', 'sans-serif'].join(','),
        color: '#013872',

        h1: {
            fontSize: '2.5rem',
            fontWeight: 'bold',
            
        }
    },
})

export default theme