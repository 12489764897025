import React from 'react'
import { connect } from 'react-redux'
import {
    Route,
    Redirect,
} from 'react-router-dom'

import helpers from '../helper'

function AuthRoute({ 
    admin,
    userId, 
    userRole,
    path,
    children 
}) {

    if (!userId) {
        return (
            <Redirect to="/" />
        )
    }

    if (admin && !helpers.users.isAdmin(userRole)) {
        return (
            <Redirect to="/account" />
        )
    }

    return (
        <Route path={path}>
            {children}
        </Route>
    )
}

export default connect(state => ({
    userId: state.auth.id,
    userRole: state.auth.roles,
})) (AuthRoute)