import React from 'react'
import {
    TableCell,
    Icon,
} from '@material-ui/core'

import BaseTable from '../BaseTable'

import MyLocationIcon from '@material-ui/icons/MyLocation'
import LocationSearchingIcon from '@material-ui/icons/LocationSearching'

function UserTable({
    data,
    onEditHandler,
    onDeleteHandler,
}) {
    const userRow = (user) => {
        return (
            <>
                <TableCell align="center">{user.username}</TableCell>
                <TableCell align="center">{user.displayname}</TableCell>
                <TableCell align="center">{user.role}</TableCell>
                <TableCell align="center"><Icon>{user.activated ? <MyLocationIcon /> : <LocationSearchingIcon /> }</Icon></TableCell>
            </>
        )
    }

    return (
        <BaseTable
            withIndex
            headers={['username', 'displayname', 'role', 'activated']}
            data={data}
            getRow={userRow}
            hasAction
            onEditHandler={onEditHandler}
            onDeleteHandler={onDeleteHandler}
        />
    )
}

export default UserTable