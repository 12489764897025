import React from 'react'
import {
    makeStyles,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    IconButton,
    Icon,
} from '@material-ui/core'

import CreateIcon from '@material-ui/icons/Create'
import EmojiEmotionsIcon from '@material-ui/icons/EmojiEmotions';

const useStyles = makeStyles({
    table: {
        width: '100%'
    }
})

function InfoTable({
    username,
    displayname,
    role,
    onChangeDisplaynameClick,
    onChangePasswordClick,
}) {
    const classes = useStyles()

    return (
        <TableContainer component={Paper}>
            <Table className={classes.table}>
                <TableHead>
                    <TableCell align="center">Field</TableCell>
                    <TableCell align="center">Value</TableCell>
                    <TableCell align="center">Action</TableCell>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell align="center">username</TableCell>
                        <TableCell align="center">{username}</TableCell>
                        <TableCell align="center">
                            <Icon>
                                <EmojiEmotionsIcon />
                            </Icon>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell align="center">display name</TableCell>
                        <TableCell align="center">{displayname}</TableCell>
                        <TableCell align="center">
                            <IconButton
                                onClick={onChangeDisplaynameClick}
                            >
                                <CreateIcon />
                            </IconButton>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell align="center">role</TableCell>
                        <TableCell align="center">{role}</TableCell>
                        <TableCell align="center">
                            <Icon>
                                <EmojiEmotionsIcon />
                            </Icon>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell align="center">password</TableCell>
                        <TableCell align="center">-</TableCell>
                        <TableCell align="center">
                            <IconButton
                                onClick={onChangePasswordClick}
                            >
                                <CreateIcon />
                            </IconButton>
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default InfoTable