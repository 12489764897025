import React, { useState } from 'react'
import './App.css';
import './styles/SideMenu.scss'
import { 
  BrowserRouter as Router, 
  Switch,
  Route,
} from 'react-router-dom'

import { ThemeProvider } from '@material-ui/core/styles'
import theme from './styles/CustomTheme'

// import { TransitionGroup, CSSTransition } from 'react-transition-group'

import AuthRoute from './components/AuthRoute'
import MobileTopBar from './components/MobileTopBar'
import SideMenu from './components/SideMenu'
import BaseContext from './components/BaseContext'

import Login from './pages/Login'

import User from './pages/User'
import Account from './pages/Account'
import Email from './pages/Email'
import APIKey from './pages/APIKey'
import Reminder from './pages/Reminder'
import Log from './pages/Log'
import TestLog from './pages/TestLog'

function App() {
  const [collapsed, setCollapse] = useState(false)
 
  const toggleCollapsed = () => {
      setCollapse(prev => !prev)
  }

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Route render={({ location }) => (
          <div className={'app'}>
              <SideMenu
                  collapsed={collapsed}
                  toggleCollapsed={toggleCollapsed}
              />
              <div className='full-screen'>
                {/* <TransitionGroup
                  style={{ height: '100%' }}
                >
                  <CSSTransition
                    timeout={500}
                    classNames='fade'
                    key={location.key}
                  > */}
                    <BaseContext>
                      <Switch>
                          <Route exact path="/">
                            <Login />
                          </Route>
                          <AuthRoute admin path="/users">
                            <User />
                          </AuthRoute>
                          <AuthRoute path="/account">
                            <Account />
                          </AuthRoute>
                          <AuthRoute path="/email">
                            <Email />
                          </AuthRoute>
                          <AuthRoute path="/apikey">
                            <APIKey />
                          </AuthRoute>
                          <AuthRoute path="/reminder">
                            <Reminder />
                          </AuthRoute>
                          <AuthRoute admin path="/log">
                            <Log />
                          </AuthRoute>
                          <AuthRoute admin path="/testlog">
                            <TestLog />
                          </AuthRoute>
                      </Switch>
                    </BaseContext>
                  {/* </CSSTransition>
                </TransitionGroup> */}
              </div>
              <MobileTopBar />
          </div>
        )} />
      </Router>
    </ThemeProvider>
  );
}

export default App;
