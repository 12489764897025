import React from 'react'
import {
    Grid,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
} from '@material-ui/core'
import dayjs from 'dayjs'

import ViewTextField from '../field/ViewTextField'


function ReminderView({
    reminder,
    emails,
    isOpen,
    setClose,
}) {
    const email = emails.find(s => s.id === reminder.emailId)
    
    const getRemindDateRow = () => {
        let reminds = []
        let current = dayjs(reminder.remindAt).subtract(reminder.remindBefore, 'day')
        for (let i = 0; i <= (reminder.remindBefore / reminder.remindInterval); i++) {
            reminds.push(
                <Grid item xs={12} md={12} lg={12} key={i}>
                    <ViewTextField
                        label={'remind #' + (i + 1)}
                        value={current.format('YYYY-MM-DD')}
                    />
                </Grid>
            )
            current = dayjs(current).add(reminder.remindInterval, 'day')
        }
        return reminds
    }

    return (
        <Dialog
            open={isOpen}
            onClose={setClose}
            scroll="paper"
            fullWidth
            maxWidth="lg"
        >
            <DialogTitle id="dialog-title">Reminder Preview</DialogTitle>
            <DialogContent>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={6} lg={6}>
                        <ViewTextField
                            label="label"
                            value={reminder.label}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                        <ViewTextField
                            label="subject"
                            value={reminder.subject}
                        />
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                        <ViewTextField
                            label="message"
                            value={reminder.message}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                        <ViewTextField
                            label="remind date"
                            value={dayjs(reminder.remindAt).format('YYYY-MM-DD')}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                        <ViewTextField
                            label="start reminding at"
                            value={dayjs(reminder.remindAt).subtract(reminder.remindBefore, 'day').format('YYYY-MM-DD')}
                        />
                    </Grid>

                    <Grid item xs={11} md={11} lg={11} fullWidth>
                        <Grid container alignItem="center" spacing={1}>
                            {getRemindDateRow()}
                        </Grid>
                    </Grid>

                    <Grid item xs={12} md={6} lg={6}>
                        <ViewTextField
                            label="will send to"
                            value={email.label}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                        <ViewTextField
                            label="withe the address"
                            value={email.address}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" color="primary" onClick={setClose}>
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default ReminderView