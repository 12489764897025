import axios from 'axios'

import store from '../store'
import actions from '../store/actions'

let instance

const getAxios = (config) => {
    if (instance) return instance(config)
  
    instance = axios.create({
      baseURL: process.env.REACT_APP_BACKEND,
      timeout: 50000,
      withCredentials: true,
    })
  
    instance.interceptors.response.use(response => response, error => {
      if (error?.response?.status === 401) {
        store.dispatch(actions.logout())
      }
  
      return Promise.reject(error)
    })
  
    return instance(config)
  }
  
  export default getAxios
  