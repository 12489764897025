import React from 'react'
import {
    TableCell,
} from '@material-ui/core'

import BaseTable from '../BaseTable'

function EmailTable({
    data, 
    onDeleteHandler,
}) {
    
    const emailRow = (email) => {
        return (
            <>
                <TableCell align="center">{email.label}</TableCell>
                <TableCell align="center">{email.address}</TableCell>
                <TableCell align="center">{email.userName}</TableCell>
            </>
        )
    }

    return (
        <BaseTable
            withIndex
            headers={['label', 'address', 'created by']}
            data={data}
            getRow={emailRow}
            hasAction
            onDeleteHandler={onDeleteHandler}
        />
    )
}

export default EmailTable