import getAxios from './axios'

const list = (page) => getAxios({
    url: `/log/${page}`,
    method: 'GET',
})

const logs = {
    list,
}

export default logs