import getAxios from './axios'

const login = (username, password) => getAxios({
    url: '/login',
    method: 'POST',
    data: {
        username,
        password,
    },
})

const logout = () => getAxios({
    url: '/logout',
    method: 'GET',
})

const me = () => getAxios({
    url: '/me',
    method: 'GET',
})

const auth = {
    login,
    logout,
    me,
}

export default auth