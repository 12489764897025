import React from 'react'
import {
    TableCell,
} from '@material-ui/core'
import dayjs from 'dayjs'

import BaseTable from '../BaseTable'

function TestLogTable({
    data,
}) {
    const logRow = (log) => {
        return (
            <>
                <TableCell align="center">{log.title}</TableCell>
                <TableCell align="center">{log.message}</TableCell>
                <TableCell align="center">{log.createdAt ? dayjs(log.createdAt).format('YYYY-MM-DD HH:mm'): '-'}</TableCell>
            </>
        )
    }

    return (
        <BaseTable
            withIndex
            headers={['title', 'message', 'sent at']}
            data={data}
            getRow={logRow}
        />
    )
}

export default TestLogTable